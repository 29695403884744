export const t = {
    title: "Wishlist",
    waiting: "...waiting",
    edit: "Edit",
    add: "Add",
    delete: "Delete",
    confirmDelete:"Are you sure you want to delete this item?",
    name: "Name",
    price: "Price",
    claimable: "Claimable",
    claimed: "Claimed",
    claim: "Claim",
    unclaim: "Unclaim",
    url: "URL",
    image: "Image URL",
    login: "Login",
    logout: "Logout",
    loginError: "Wrong username or password",
    username: "Username",
    password: "Password",
    yourMail: "Your Mail address (optional)",
    mailDisclaimer: "Your mail address is not manditory. It will only be used to send you a single email to undo your claim and will not be saved."
}